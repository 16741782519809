/* media query */
@media (max-width: 3000px) {
  .adfit-l,
  .adfit-l2 {
    display: block;
    border-radius: 5px;
  }
  .adfit-mt2 {
    margin-top: 2rem;
  }
  .adfit-m,
  .adfit-m2,
  .adfit-m3 {
    display: none;
  }
  .indicatorContainer.itemDetail {
    margin: 30px 0;
    width: 100%;
  }
  .indicator-arrow {
    display: none;
  }
}

@media (max-width: 1399px) {
  .itemGridContainer {
    margin: 0 3% 2rem;
    grid-template-columns: repeat(6, 1fr);
  }
  .item__img img {
    max-height: 515px;
  }
  #disqus_thread {
    padding: 2rem 4%;
  }
  .indicatorContainer {
    width: 94%;
    grid-template-columns: repeat(8, minmax(80px, auto));
    overflow: scroll;
    scrollbar-width: none;
    margin: 3%;
    font-size: 0.7rem;
  }
  .indicatorContainer::-webkit-scrollbar {
    display: none;
  }
  .indicatorContainer > div > span {
    font-size: 0.7rem;
  }
  .indicatorContainer.itemDetail {
    margin: 30px 0;
    width: 100%;
  }
  .indicator-arrow {
    display: none;
  }
  .searchContainer__wrap {
    width: 94%;
    margin: 30px auto 0;
  }
  .searchContainer {
    margin: 0 auto;
    grid-template-columns: 2rem 0.5fr 1fr 1fr 0.5fr 2rem;
  }
  .searchInput__date {
    grid-column: 4/6;
    grid-row: 2/3;
  }
  .searchInput__date > input {
    font-size: 0.8rem;
  }
  .searchInput__keyword {
    grid-column: 2/4;
    grid-row: 2/3;
  }
  .keyword__title {
    grid-column: 2/3;
    grid-row: 3/4;
  }
  .keywordWrap {
    grid-column: 3/6;
    grid-row: 3/4;
  }
  .weather__etc span i {
    display: block;
    margin-bottom: 5px;
  }
  .weather__city select {
    width: auto;
  }
  .adfit-l,
  .adfit-l2 {
    width: 94%;
    display: block;
    border-radius: 5px;
  }
  .adfit-m,
  .adfit-m2,
  .adfit-m3 {
    display: none;
  }
}

@media (max-width: 1000px) {
  .itemGridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 600px) {
  nav {
    flex-direction: column;
    height: 100px;
  }
  .brand {
    font-size: 18px;
  }
  .news__noti {
    grid-row: 1;
    padding: 0 2rem;
  }
  .wrapContainer {
    height: 100%;
    padding-top: 100px;
  }
  .homeContainer {
    padding-top: 100px;
  }
  .itemGridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .itemContainer {
    outline: none;
  }
  .itemTitle {
    width: 94%;
    margin: 0 auto;
    text-align: center;
  }
  .item__date {
    font-size: 1.2rem;
  }
  .factoryInput__title {
    display: block;
    padding: 2%;
  }
  .factoryInput__title > input {
    height: 1.8rem;
  }
  .factoryInput__title > input:nth-child(1) {
    width: 100%;
    border: none;
  }
  .factoryInput__title > input:nth-child(2) {
    width: 50%;
  }
  .factoryInput__title > input:nth-child(3) {
    width: 25%;
  }
  .factoryInput__title > input:nth-child(4) {
    width: 25%;
  }
  .newsGrid {
    grid-template-columns: 10px 1fr 10px;
    grid-template-rows: 50px 120px 1fr 150px;
  }
  .news__type {
    z-index: 10;
    justify-content: space-evenly;
    grid-column: 2;
    grid-row: 1;
    flex-direction: row;
    bottom: 0;
    left: 0;
    gap: 0.8rem;
    width: 100%;
    padding: 3% 3% 6%;
    font-size: 0.7rem;
    background-color: #000;
    border-top: 1px solid #222;
    box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.3);
  }
  .news__type label {
    flex-direction: column;
    height: auto;
    width: auto;
    padding: 8px 10px;
    border-radius: 6px;
    background-color: black;
    font-size: 0.6rem;
  }
  .news__type label:hover,
  .news__type label.selected {
    background-image: none;
    color: #f8ff8d;
  }
  .news__type label > svg {
    font-size: 1rem;
    margin-bottom: 5px;
    margin-left: 0;
    margin-right: 0;
  }
  .newsContainer {
    border: 0;
    margin: 0;
  }
  .moreBtnWrap {
    grid-column: 2;
    grid-row: 4;
  }
  .news__empty.item__empty {
    grid-column: 1;
    font-size: 18px;
  }
  .indicator-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    /* padding: 30px 0; */
    position: relative;
  }
  .indicatorContainer.itemDetail {
    width: 90%;
    position: relative;
  }
  .indicator-arrow {
    display: block;
    flex: 1 1 50%;
    padding: 0 3px;
    margin: 0 4px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 100px;
    color:#fff;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 10;
    position: absolute;
    top: calc(50% - 15px);
  }
  .indicator-arrow.prev {
    left: 0;
  }
  .indicator-arrow.next {
    right: 0;
  }
  .searchContainer__wrap {
    margin-top: 0;
  }
  .searchContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 4fr;
    /* margin: 3% 3% 0; */
    text-align: center;
    grid-column: 1/10;
    grid-row: 2;
  }
  .searchInput__date {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
  }
  .searchInput__date > input {
    height: 2rem;
    min-width: 40%;
    margin: 2%;
    font-size: 0.8rem;
    text-indent: 3px;
  }
  input::-webkit-datetime-edit {
    min-width: 40%;
  }
  .searchInput__keyword {
    grid-row: 2;
    grid-column: 1;
    width: 94%;
    margin: 3%;
  }
  .keyword__title {
    grid-row: 3;
    grid-column: 1;
    width: 100%;
    border: 0;
  }
  .keywordWrap {
    grid-row: 4;
    grid-column: 1;
    margin: 0;
    padding: 1rem;
  }
  .weatherContainer {
    /* width: 94%; */
    margin: 0 auto;
    grid-column: 1/10;
    grid-row: 1;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr 1fr 1fr;
  }
  .weather__city {
    grid-column: 1/4;
  }
  .weather__icon {
    grid-row: 2;
    grid-column: 1;
  }
  .weather__temp {
    grid-row: 2;
    grid-column: 2;
    font-size: 1.3rem;
    align-self: center;
    background-color: #111518;
  }
  .weather__desc {
    grid-column: 3;
    grid-row: 2;
    align-self: center;
    background-color: #111518;
  }
  .weather__etc {
    grid-column: 1/4;
    grid-row: 3;
    display: flex;
    justify-content: space-evenly;
  }
  .item__img img:hover,
  .item__img img:focus {
    transform: none;
    outline: none;
  }
  .item__newson {
    /* top: 10px; */
    display: none;
  }
  .item__text > img {
    width: 100%;
  }

  .detail__top,
  .detail__content,
  .detail__content__wrap,
  .detail__tag {
    width: 90%;
  }
  .tagBtn {
    width: 30%;
  }
  .detail__news {
    padding-bottom: 18%;
  }
  .detail__content__more {
    bottom: 25px;
  }
  .wrapContainer .adfit-m2,
  .wrapContainer .adfit-m3 {
    width: 100%;
  }
  .adfit-l,
  .adfit-l2 {
    display: none;
  }
  .adfit-m2 {
    margin-bottom: 3%;
  }
  .adfit-m,
  .adfit-m2,
  .adfit-m3 {
    display: block;
  }
  .factoryInput__arrow {
    width: 70px;
  }
  .edit_wrap p {
    font-size: 16px;
  }
  .ck.ck-editor {
    width: 94%;
  }
  .ck-content > p {
    font-size: 16px;
  }
  .appFooter {
    padding-bottom: 80px;
  }
}
