@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard.css");

@font-face {
  font-family: 'Gowun Dodum';
  src: local('Gowun Dodum'), 
      url('../public/subset-GowunDodum-Regular.woff2') format('woff2'),
      url('../public/subset-GowunDodum-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

* {
  box-sizing: border-box;
}

input {
  /* all: unset; */
  box-sizing: border-box;
  border: none;
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  /* appearance: none; */
}

body {
  background-color: #000;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
  color: #333;
}

button {
  background-color: white;
  color: black;
}

a {
  text-decoration: none;
  color: inherit;
}

form {
  width: 100%;
}

.wrapContainer {
  min-height: 92vh;
  padding-top: 77px;
}

/* Logo */
.brand img {
  margin-right: 12px;
  width: 34px;
  vertical-align: middle;
}

.brand {
  font-size: 22px;
  font-family: "Gowun Dodum", Pretendard, sans-serif;
  color: #fff;
}

/* Nav */
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1.4rem;
  position: fixed;
  z-index: 10;
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  background: rgba(0, 0, 0, 0.1);
  /* for iPhone */
	-webkit-backdrop-filter: saturate(180%) blur(15px);
	-moz-backdrop-filter: saturate(180%) blur(15px);
	-o-backdrop-filter: saturate(180%) blur(15px);
	backdrop-filter: saturate(180%) blur(15px);
  transition: all 0.3s ease-in-out;
}
.nav > ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 60px;
}

.container {
  width: 100%;
  max-width: 800px;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.container h2 {
  font-size: 1.4rem;
  margin: 20px;
  color: #cfcfcf;
}

.homeContainer {
  min-height: 92vh;
  padding: 1%;
  padding-top: 76px;
}

.formInput {
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  border: 1px solid black;
  text-align: center;
  background-color: white;
  color: black;
}

.commonBtn {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(255, 255, 255, 0.1) 100%
  );
}
.commonBtn:hover,
.commonBtn:focus {
  background-image: none;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.3);
}
.formBtn {
  cursor: pointer;
  width: 100%;
  padding: 7px 15px;
  font-size: 0.8rem;
  text-align: center;
  color: #fff;
  font-weight: 700;
  border-radius: 20px;
  background-color: #35405e;
  cursor: pointer;
}
.formBtn:active {
  opacity: 0.8;
}
.homeBtn {
  background-color: #fff;
  color: #35405e;
}

.writeBtn {
  background-color: tomato;
  color: #fff;
}

.cancelBtn {
  background-color: #242b3c;
  color: #fff;
}
.smallBtn {
  width: auto;
  padding: 5px 8px;
  font-size: 0.6rem;
}

/* Auth */
.authContainer {
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  justify-content: center;
  align-items: center;
}

.authBtns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 320px;
}

.authBtn {
  cursor: pointer;
  border-radius: 20px;
  border: none;
  padding: 10px 0px;
  font-size: 12px;
  text-align: center;
  width: 150px;
}

/* AuthForm */

.authInput {
  max-width: 320px;
  width: 100%;
  padding: 10px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  font-size: 12px;
  color: black;
}

.authSubmit {
  text-align: center;
  background-color: #ffcc00;
  color: #333;
  margin-top: 10px;
  cursor: pointer;
}

.authError {
  color: tomato;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
}

.authSwitch {
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 50px;
  display: block;
  font-size: 12px;
  text-decoration: underline;
}

.appFooter {
  padding: 30px 0 0;
  text-align: center;
  color: #999;
}
.appFooter address {
  margin: 10px auto;
}
.privacy {
  cursor: pointer;
}

/* Item Detail */

.detail__top {
  width: 80%;
  margin: 20px auto 0;
}

.detail__top > h1 {
  text-align: center;
  color: #999;
  margin-bottom: 10px;
  font-size: 14px;
}

.detail__top > h2 {
  color: #fff;
}

.detail__top > .item__temperature {
  height: 32px;
  font-weight: bold;
}

.detail__img > img {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.detail__content {
  width: 80%;
}

.detail__text {
  background-color: #fff;
  margin: 50px 0;
  border-radius: 10px;
  line-height: 1.6;
  font-size: 16px;
  padding: 8%;
}

.detail__text:nth-child(1) {
  background-color: #222;
  color: #fff;
  margin-top: 0;
}

.detail__tag {
  width: 80%;
  margin-top: 20px;
}

.detail__tag h5 {
  text-align: center;
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
}

.detail__tag .tagBtn {
  padding: 10px;
  margin-bottom: 10px;
  font-weight: 400;
  cursor: pointer;
}

.detail__btns {
  margin: 30px;
  text-align: center;
}

.detail__btns > .detail__btn__kakao {
  display: inline-block;
  cursor: pointer;
  margin: 1rem;
  width: 120px;
  padding: 10px 0px;
  text-align: center;
  border-radius: 30px;
  color: #000;
  background-image: linear-gradient(to bottom, #fff171 0%, #ffd900e7 100%);
  background-size: 200% auto;
  border: none;
}

.detail__news {
  background-color: #fff;
  margin: 0 0 20px;
  border-radius: 10px;
  line-height: 1.8;
  font-size: 16px;
  padding: 8% 8% 10%;
  min-height: 300px;
}

.detail__news img {
  width: 100%;
}

h2.detail__news__title {
  line-height: 1.3;
  margin: 0;
}

.detail__news__type {
  display: inline-block;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
  color: #ccc;
  padding: 4px 10px;
  background-color: #333;
  border-radius: 30px;
}

.detail__news__date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail__news__creator {
  margin-left: 10px;
}

.detail__content__wrap {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.detail__content__more {
  position: absolute;
  bottom: 30px;
  left: calc(50% - 48px);
}

/* Item Factory */

.factoryForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.factoryInput__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}
.newsFactoryInput__radio {
  color: #fff;
  margin-bottom: 1rem;
}
.newsFactoryInput__radio label {
  font-size: 1rem;
  margin-right: 2rem;
}
.newsFactoryInput__radio label:last-child {
  margin-right: 0;
}

.factoryInput__title {
  display: flex;
  width: 94%;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
}
.newsFactoryInput__title {
  display: flex;
  width: 94%;
  gap: 1rem;
  align-items: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 10px;
}
.newsFactoryInput__title span {
  color: #8f8f8f;
  flex-shrink: 0;
}
.newsFactoryInput__title input {
  flex-grow: 1;
}
textarea.newsFactoryInput__title {
  min-height: 12rem;
}
.factoryInput__img {
  display: flex;
  width: 94%;
  gap: 0.5rem;
  align-items: center;
  padding: 8px;
  background-color: #333;
  border-radius: 5px;
  margin-bottom: 10px;
}
.factoryInput__img > input:nth-child(1) {
  flex-grow: 1;
  padding: 4px;
  border-radius: 30px;
}

.factoryInput__title > input {
  width: 12%;
  padding: 0 1%;
  justify-content: center;
  border-right: 1px solid #ddd;
}

.factoryInput__title > input:nth-child(1) {
  width: 56%;
}
.factoryInput__title > input:nth-child(2) {
  width: 23%;
}

.factoryInput__title > input:nth-child(4) {
  border: none;
}
.factoryInput__tag {
  width: 100%;
  border: 0;
  text-align: center;
}
.factoryInput__tag > input {
  display: block;
  width: 30%;
  margin: 10px auto;
}
.tagBtn {
  cursor: default;
  display: inline-block;
  width: 18%;
  padding: 5px 10px;
  margin: 5px 5px 0;
}

.ck.ck-reset.ck-editor {
  width: 94%;
}

.ck-content,
.factoryInput__textarea {
  flex-grow: 1;
  height: 500px;
  padding: 20px;
  color: #333;
  border: 1px solid white;
  border-radius: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.6rem;
}

.ck-content > p {
  margin: 20px auto;
  font-size: 14px;
}
.submitBtns {
  width: 94%;
  text-align: right;
}

.factoryInput__arrow {
  display: inline-block;
  cursor: pointer;
  margin-top: 1rem;
  height: 40px;
  width: 100px;
  padding: 10px 0px;
  text-align: center;
  border-radius: 20px;
  color: white;
  background-image: linear-gradient(to bottom, #00d2ff 0%, #845ec2 100%);
  background-size: 200% auto;
}

.topicEdit {
  margin-left: 10px;
  background-image: linear-gradient(to bottom, #ff826c 0%, #dd213a 100%);
}

.factoryInput__label {
  color: #04aaff;
  cursor: pointer;
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: white;
}

.factoryInput__label span {
  margin-right: 10px;
  font-size: 12px;
}

.factoryForm__attachment {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.factoryForm__attachment img {
  height: 200px;
  width: 200px;
  border-radius: 4px;
}

.factoryForm__clear {
  color: tomato;
  cursor: pointer;
  width: 120px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  margin: 15px auto;
  background-color: white;
}

.factoryForm__clear span {
  margin-right: 10px;
  font-size: 11px;
  font-weight: bold;
}

/* Item */
.itemGridContainer {
  display: grid;
  grid-gap: 1rem;
  margin: 0 auto 2rem;
  grid-template-columns: repeat(auto-fill, minmax(210px, auto));
  max-width: 1400px;
}
.itemTitle {
  margin: 1rem 3% 0;
  font-size: 32px;
  text-align: center;
}
.itemContainer {
  position: relative;
  margin-top: 1rem;
}
.item__actions {
  position: absolute;
  right: 0;
  color: #999;
}
.item__actions span {
  margin-left: 1rem;
}

.item__temperature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 5rem;
  color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.item__temperature span:nth-child(1),
.item__temperature span:nth-child(2) {
  margin: 9px 12px;
}
.item__newson {
  z-index: 1;
  position: absolute;
  top: 33px;
  text-align: center;
  color: #fff;
  width: 100%;
}
.temp__none {
  background-color: #35405e;
  color: #35405e;
}
.temp__cold20 {
  background-image: linear-gradient(90deg, #090979 0%, #002fff 100%);
}
.temp__cold10 {
  background-image: linear-gradient(90deg, #002fff 0%, #008cff 100%);
}
.temp__cold2 {
  background-image: linear-gradient(90deg, #008cff 0%, #45d6b7 100%);
}
.temp__spring {
  background-image: linear-gradient(90deg, #45d6b7 0%, #29c536 100%);
}
.temp__hot15 {
  background-image: linear-gradient(90deg, #29c536 0%, #1b8600 100%);
}
.temp__hot30 {
  background-image: linear-gradient(90deg, #1b8600 0%, #a2a500 100%);
}
.temp__hot40 {
  background-image: linear-gradient(90deg, #a2a500 0%, #ffc400 100%);
}
.temp__hot50 {
  background-image: linear-gradient(90deg, #ffc400 0%, #ff6600 100%);
}

.item__text {
  font-size: 0.85rem;
  line-height: 1.6rem;
}
.item__text > p {
  margin: 20px auto;
}
.item__text > img {
  max-width: 100%;
}
.item__date {
  font-size: 0.8rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
.item__img {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-top: -3rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.item__img img {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  max-height: 267px;
}

.item__img img:hover,
.item__img img:focus {
  cursor: pointer;
  transform: translateY(20px);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
  transition: 0.2s;
}

/* News */
.newsGrid {
  display: grid;
  grid-template-columns: 200px 1fr 100px;
  grid-template-rows: 50px 90px 1fr 30px;
}
.news__noti {
  grid-column: 2;
  text-align: center;
  align-self: center;
  color: #464d5a;
  font-size: 0.7rem;
}
.news__noti:hover,
.news__noti:focus {
  color: #ffcc00;
  transition: 0.2s;
}
/* NewsType */
.news__type {
  grid-column: 1;
  grid-row: 2;
  position: fixed;
  margin-top: 4rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.news__type label {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  width: 100px;
  border-radius: 20px;
  color: white;
  font-size: 0.8rem;
  background-color: #35405e;
  background-size: 200% auto;
}
.news__type label:hover,
.news__type label.selected {
  background-image: linear-gradient(180deg, #ded860 0%, #8dadc2 100%);
  color: #000;
  font-weight: bold;
}
.news__type label.selected > svg {
  color: #f8ff8d;
}
.news__type label > svg {
  margin: 0 6px 0 10px;
  color: #78788b;
  font-size: 0.7rem;
}
.news__type input {
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  margin: 0;
  padding: 0;
}
.newsGridContainer {
  grid-column: 2;
  grid-row: 3;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, auto));
  justify-self: flex-start;
  align-self: flex-start;
  grid-gap: 1rem;
  margin: 0 auto;
  color: #666;
  text-align: left;
}

.newsContainer {
  z-index: 1;
  cursor: pointer;
  position: relative;
  margin: 0.8rem 0;
  border-radius: 5px;
  background-color: #111518;
  border: 1px solid #222;
  transition: 0.3s;
}
.newsContainer:hover,
.newsContainer:focus {
  border-color: #468a7b;
}
.__Newsletter {
  border-color: rgba(219, 235, 0, 0.15);
}
.__Newsletter:hover,
.__Newsletter:focus {
  border-color: rgb(219, 235, 0);
}
.__Bookmark {
  border-color: rgba(182, 0, 182, 0.25);
}
.__Bookmark:hover,
.__Bookmark:focus {
  border-color: rgb(182, 0, 182);
}
.__Etc {
  border-color: rgba(0, 153, 196, 0.2);
}
.__Etc:hover,
.__Etc:focus {
  border-color: rgb(0, 153, 196);
}
.newsContainer__link {
  padding: 1rem;
}
.news__actions {
  z-index: 2;
  margin-top: 1rem;
  margin-right: 1rem;
}
.news__date {
  font-size: 0.8rem;
  color: #7a8e96;
}
.news__creator {
  color: #666;
}
.news__title {
  margin: 10px 0;
  line-height: 1.2rem;
  font-weight: bold;
  color: #fff;
}
.news__text {
  height: 37px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2rem;
  color: #999;
  font-size: 0.8rem;
}
.moreBtnWrap {
  display: flex;
  width: 67%;
  margin: 0 auto;
  height: 2rem;
  grid-column: 2;
  grid-row: 4;
  align-self: center;
  justify-self: center;
}
.moreBtn {
  display: block;
  align-items: center;
  font-size: 1.1rem;
}

.empty {
  width: 100%;
  height: 80vh;
  text-align: center;
  line-height: 80vh;
}
.news__empty {
  grid-column: 2;
  grid-row: 2;
  justify-self: center;
  align-self: center;
}
.news__empty.item__empty {
  grid-column: 1/7;
  font-size: 24px;
  font-weight: 200;
  line-height: 160px;
}
.news__empty.item__empty > i {
  color: #fff171;
  font-weight: 700;
  padding-right: 5px;
}
.loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}
.loader {
  width: 60px;
  height: 60px;
}

.loader::before {
content: "";
box-sizing: border-box;
position: absolute;
width: 60px;
height: 60px;
border-radius: 50%;
border-top: 2px solid #fff;
border-right: 2px solid transparent;
animation: spinner8217 0.8s linear infinite;
}

@keyframes spinner8217 {
to {
  transform: rotate(360deg);
}
}

/* dark ver */
.dark {
  background-color: #000;
  color: #fff;
}

/* white ver */
.bright {
  background-color: #f4f4f0;
  color: #000;
}
.nav__bright {
  background-color: #f4f4f0;
  border-bottom: 1px solid #000;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
.brand__bright {
  color: #000;
}
.newsContainer__bright {
  background-color: #fff;
  border: 1px solid #000;
  transition: 0.2s;
}
.news__date__bright {
  color: #999;
}
.news__title__bright {
  color: #000;
}
.news__text__bright {
  color: #666;
}
.__Newsletter__bright {
  border-color: #111f0b;
}
.__Newsletter__bright:hover,
.__Newsletter__bright:focus {
  border-color: #227000;
}
.__Bookmark__bright {
  border-color: #564147;
}
.__Bookmark__bright:hover,
.__Bookmark__bright:focus {
  border-color: #86203d;
}
.__Etc__bright {
  border-color: #254d4c;
}
.__Etc__bright:hover,
.__Etc__bright:focus {
  border-color: #0f8886;
}
.button__link {
  align-self: flex-end;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 8px 15px;
  font-size: 0.7rem;
  background-color: #35405e;
  color: #fff;
  border-radius: 100px;
}

/* search */
.homeFlex {
  max-width: 1400px;
  margin: 0 auto;
}
.searchContainer__wrap {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  margin-top: 30px;
  gap: 1rem;
}
.searchContainer {
  grid-column: 1/7;
  display: grid;
  grid-template-columns: 1rem 2rem 120px 2fr 2fr 2rem;
  grid-template-rows: 2rem 1fr 1fr 2rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  background-color: #111518;
}
.searchInput__keyword {
  grid-column: 3/5;
  grid-row: 2;
  width: 100%;
  position: relative;
}
.searchInput__keyword > input {
  width: 100%;
  height: 2.7rem;
  border-radius: 5px;
  background-color: #fff;
  font-size: 1rem;
  color: #666;
  text-indent: 3rem;
}
.searchCancelBtn {
  cursor: pointer;
  position: absolute;
  left: 1.5rem;
  top: 1rem;
  color: #333;
}
.searchContainer input:focus {
  outline: none;
}
.searchInput__date {
  grid-column: 5;
  grid-row: 2;
}
.searchInput__date > input {
  height: 2.7rem;
  border-radius: 5px;
  margin: 0 1rem;
  padding-right: 5px;
  font-size: 1rem;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: #fff;
  background-color: #303540;
  font-weight: bold;
  text-align: center;
}
::-webkit-calendar-picker-indicator {
  margin-left: 0px;
}
.keyword__title {
  grid-row: 3;
  grid-column: 3;
  font-weight: 700;
  margin-top: 10px;
  color: #fff;
  border-right: 1px solid #666;
}
.keywordWrap {
  grid-column: 4/6;
  grid-row: 3;
  display: flex;
  gap: 1rem;
  margin: 10px 0 0 10px;
  flex-flow: row wrap;
}
.issueKeyword {
  cursor: pointer;
}
.issueKeyword:hover,
.issueKeyword:focus {
  color: #fff;
  font-weight: bold;
}
.infoWrap .container {
  color: #fff;
  text-align: left;
  padding-top: 8rem;
  align-items: flex-start;
}
.infoWrap__logo {
  margin: 0 auto 30px;
}
.infoWrap .container p,
.infoWrap .container li {
  margin: 10px 0;
  line-height: 1.6rem;
}

#disqus_thread {
  width: 100%;
  margin: 3rem auto 0;
  padding: 2rem 0;
  background-color: #000;
  border-top: 1px solid #222;
}

/* indicator */
.indicatorContainer {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  max-width: 1400px;
  margin: 30px auto 0;
  gap: 1rem;
}
.indicatorContainer > div {
  border-radius: 5px;
  background-color: #111518;
  height: 5rem;
  line-height: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Roboto, Noto Sans KR, sans-serif, Arial, Helvetica;
}
.indicatorContainer h3 {
  color: #ccc;
}
.indicatorContainer > div > span {
  font-weight: 700;
  color: #fb6e55;
}
.indicatorContainer > div > span:nth-child(3) {
  background-color: #fb6e55;
  padding: 0 6px;
  color: #fff;
  border-radius: 4px;
}
.indicatorContainer > div.minus > span {
  color: #4b67e7;
}
.indicatorContainer > div.minus > span:nth-child(3) {
  background-color: #2a4ad8;
  color: #fff;
}

/* weather */
.weatherContainer {
  grid-column: 7/9;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 0.5fr 1fr;
  align-items: center;
  justify-items: center;
  border-radius: 5px;
  background-color: #111518;
}
.weather__city {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1;
  grid-column: 1/3;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #222;
  color: #888;
}
.weather__city h3 {
  display: inline-block;
  margin: 0 20px 0 10px;
}
.weather__city select {
  justify-self: start;
  width: 120px;
  height: 2rem;
  border: 0;
  border-radius: 4px;
  color: #000;
  text-align: center;
  font-weight: bold;
}
.weather__icon {
  grid-row: 2/4;
  grid-column: 1;
}
.weather__temp {
  grid-row: 2;
  grid-column: 2;
  justify-self: start;
  font-size: 1.5rem;
  width: 130px;
  height: 2.3rem;
  line-height: 2.3rem;
  text-align: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.weather__desc {
  grid-row: 3;
  grid-column: 2;
  justify-self: start;
  align-self: start;
  width: 130px;
  overflow: scroll;
  font-size: 0.7rem;
  height: 1.3rem;
  line-height: 1.3rem;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.weather__desc::-webkit-scrollbar {
  display: none;
}
.weather__etc {
  grid-row: 4;
  grid-column: 1/3;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 10px 0;
  border-top: 1px solid #222;
  font-size: 0.8rem;
}
.weather__etc span i {
  color: #888;
  margin-right: 8px;
}

.googleAd {
  width: 100%;
  margin: 30px auto 0;
  text-align: center;
}

.adfit {
  margin: 0 auto;
  grid-row: 2;
  grid-column: 2;
  text-align: center;
}

.kakaochat {
  display: block;
  margin: 20px auto 0;
  padding: 15px 10px;
  border-top: 1px solid #222;
}

/* editor */
.edit_wrap {
  width: 94%;
}
